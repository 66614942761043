// src/hooks/useMappy.js
import { useState, useEffect } from 'react';

import useFileManagement from './useFileManagement';
import useColumns from './useColumns';
import useValidation from './useValidation';
import useReordering from './useReordering';
import useZones from './useZones';
import * as XLSX from 'xlsx';

function sortAddresses(addressList, orderBy, sortOrder) {
  if (!addressList.length || !orderBy) return addressList;

  const colIndex = XLSX.utils.decode_col(orderBy.trim().toUpperCase());
  const sorted = [...addressList];
  sorted.sort((a, b) => {
    const aValue = a.row[colIndex] ?? '';
    const bValue = b.row[colIndex] ?? '';
    return sortOrder === 'asc'
      ? aValue.localeCompare(bValue)
      : bValue.localeCompare(aValue);
  });
  return sorted;
}

export default function useMappy({ autoExtract = false }) {
    // Integrate useFileManagement
    const {
        fileInputRef,
        data,
        uploadedFileName,
        setUploadedFileName,
        isLoading,
        handleFileChange,
        resetFileManagement,
    } = useFileManagement();

    // Integrate useReordering
    const {
        reorderedAddresses,
        setReorderedAddresses,
        handleOnDragEnd: rawHandleOnDragEnd,
        handleMoveUp: rawHandleMoveUp,
        handleMoveDown: rawHandleMoveDown,
        handleRemoveAddress,
        resetReordering,
    } = useReordering();

    // Integrate useValidation
    const {
        addressValidation,
        setAddressValidation,
        isValidating,
        validateAddresses,
        resetValidation,
    } = useValidation();

    // Integrate useZones
    const {
        zones,
        setZones,
        shortUrls,
        setShortUrls,
        isSubmitting,
        handleSubmit: zonesHandleSubmit,
        resetZones,
    } = useZones();

    // Integrate useColumns, passing in data from useFileManagement
    const {
        headerRow,
        setHeaderRow,
        headerRowError,
        startRow,
        setStartRow,
        startRowError,
        columnsOptions,
        setColumnsOptions,
        selectedColumns,
        setSelectedColumns,
        addresses,
        handleHeaderRowChange,
        handleStartRowChange,
        handleSelectedColumnsChange,
        resetColumns,
    } = useColumns(data, resetReordering, resetZones);

    // Whether the addresses panel is collapsed (if zones appear, etc.)
    const [addressesCollapsed, setAddressesCollapsed] = useState(false);

    // Sorting state (controlled in aggregator)
    const [orderBy, setOrderBy] = useState('');
    const [sortOrder, setSortOrder] = useState('asc');
    const [isSortVisible, setIsSortVisible] = useState(false);

    // Combined Reset Function
    const combinedReset = (fullReset = true) => {
        if (fullReset) {
            resetFileManagement();
            resetColumns();
            resetReordering();
            resetValidation();
            resetZones();
            setAddressesCollapsed(false);
        } else {
            // Partial reset: Reset everything except file, headerRow, and startRow
            setColumnsOptions([]);
            setSelectedColumns([]);
            resetReordering();
            resetValidation();
            resetZones();
            setReorderedAddresses([]);
            setOrderBy('');
            setSortOrder('asc');
            setAddressesCollapsed(false);
        }
    };

    // Handle Reset Button Click (Full Reset)
    const handleReset = () => {
        combinedReset(true);
    };

    // Handle File Change (Full Reset before loading new file)
    const handleFileUpload = (file) => {
        combinedReset(true);
        handleFileChange(file);
    };

    const handleAddressImport = (address) => {
        const newAddress = { id: Date.now(), address, validation: null };
        setReorderedAddresses((prev) => [...prev, newAddress]);
        // You can trigger validation here or defer it as per your existing logic
    };

    // Immediately sort when user changes orderBy
    const handleOrderByChange = (col) => {
        setOrderBy(col);

        // Sort right away
        const sorted = sortAddresses(addresses, col, sortOrder);
        setReorderedAddresses(sorted);
    };

    // Immediately sort when user changes sortOrder
    const handleSortOrderChange = (order) => {
        setSortOrder(order);

        // Sort right away
        const sorted = sortAddresses(addresses, orderBy, order);
        setReorderedAddresses(sorted);
    };

    // Extract and Validate Addresses (version 1)
    const extractAndValidateAddresses = async () => {
        const finalAddresses = sortAddresses(addresses, orderBy, sortOrder);
        setReorderedAddresses(finalAddresses);
        await validateAddresses(finalAddresses);
    };

    // Automatically extract addresses if autoExtract is true
    useEffect(() => {
        if (autoExtract && data.length > 0 && selectedColumns.length > 0) {
            setReorderedAddresses(addresses);
        }
    }, [autoExtract, data, selectedColumns, addresses, setReorderedAddresses]);

    // If no columns selected => clear reordering & zones
    useEffect(() => {
        if (selectedColumns.length === 0) {
            setReorderedAddresses([]);
            setZones([]);
            setOrderBy('');
            setSortOrder('asc');
            //combinedReset(false); provjeriti za ovo
        }
    }, [selectedColumns, setReorderedAddresses, setZones]);

    // Show/hide sort controls
    useEffect(() => {
        setIsSortVisible(columnsOptions.length > 0 && selectedColumns.length > 0);
    }, [columnsOptions, selectedColumns]);

    // Used for 'auto' ordering on change for V1
    useEffect(() => {
        if (reorderedAddresses.length > 0 && orderBy) {
            const colIndex = XLSX.utils.decode_col(orderBy.trim().toUpperCase());
            const sortedAddresses = [...reorderedAddresses].sort((a, b) => {
                const aValue = a.row[colIndex] || '';
                const bValue = b.row[colIndex] || '';
                return sortOrder === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            });

            // Update state only if the sorted array is different
            if (JSON.stringify(sortedAddresses) !== JSON.stringify(reorderedAddresses)) {
                setReorderedAddresses(sortedAddresses);
            }
        }
    }, [orderBy, sortOrder, reorderedAddresses, setReorderedAddresses]);

    const handleOnDragEnd = (result) => {
        rawHandleOnDragEnd(result);
        setOrderBy('');
    };

    const handleMoveUp = (index) => {
        rawHandleMoveUp(index);
        setOrderBy('');
    };

    const handleMoveDown = (index) => {
        rawHandleMoveDown(index);
        setOrderBy('');
    };

    // Overwrite handleSubmit so we pass in the final addresses + validation
    const handleSubmit = async () => {
        await zonesHandleSubmit(reorderedAddresses, addressValidation);
    };

    return {
        // File states
        fileInputRef,
        data,
        uploadedFileName,
        setUploadedFileName,
        isLoading,
        handleFileUpload,

        // QR code,
        handleAddressImport,

        // Column states and handlers
        headerRow,
        setHeaderRow,
        headerRowError,
        startRow,
        setStartRow,
        startRowError,
        columnsOptions,
        selectedColumns,
        setSelectedColumns,
        addresses,
        handleHeaderRowChange,
        handleStartRowChange,
        handleSelectedColumnsChange,

        // Sorting
        orderBy,
        setOrderBy,
        sortOrder,
        setSortOrder,
        isSortVisible,
        handleOrderByChange,
        handleSortOrderChange,

        // Reordering states and handlers
        reorderedAddresses,
        setReorderedAddresses,
        handleOnDragEnd,
        handleMoveUp,
        handleMoveDown,
        handleRemoveAddress,

        // Validation states and handlers
        addressValidation,
        setAddressValidation,
        isValidating,
        validateAddresses,
        extractAndValidateAddresses,

        // Zones states and handlers
        zones,
        setZones,
        shortUrls,
        setShortUrls,
        isSubmitting,
        handleSubmit,

        // Collapsable addresses
        addressesCollapsed,
        setAddressesCollapsed,

        // Reset functions
        handleReset,
        combinedReset,
    };
}
