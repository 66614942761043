// src/components/QrScannerButton.js
import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import QrScanner from 'react-qr-scanner';
import { QrcodeOutlined } from '@ant-design/icons';
import '../../styles/QrScannerButton.css';

const QrScannerButton = ({ handleScanResult }) => {
    const { t } = useTranslation();
    const [isScannerVisible, setIsScannerVisible] = useState(false);

    const handleScan = (data) => {
        if (data) {
            console.log('QR code handleScan:', data);
            handleScanResult(data);
            setIsScannerVisible(false); // Hide scanner after successful scan
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const toggleScannerVisibility = () => {
        setIsScannerVisible(!isScannerVisible);
    };

    useEffect(() => {
        // Stop the video stream when the modal is closed
        if (!isScannerVisible) {
            const video = document.querySelector('video');
            if (video && video.srcObject) {
                const stream = video.srcObject;
                const tracks = stream.getTracks();

                tracks.forEach(track => {
                    track.stop();
                });

                video.srcObject = null;
            }
        }
    }, [isScannerVisible]);

    return (
        <div>
            <Button icon={<QrcodeOutlined />} onClick={toggleScannerVisibility}>
                {t('mappy.scanQrCode')}
            </Button>
            <Modal
                open={isScannerVisible}
                footer={null}
                onCancel={toggleScannerVisibility}
                wrapClassName="qr-scanner-modal"
                sx={{ padding: 0 }}
                centered
            >
                <QrScanner
                    delay={300}
                    onError={handleError}
                    onScan={handleScan}
                    sx={{ width: '100%', height: '100%', maxWidth: '500px', maxHeight: '500px' }}
                    constraints={{ video: { facingMode: "environment" } }}
                />
                <div className="qr-frame"></div>
            </Modal>
        </div>
    );
};

export default QrScannerButton;