// src/components/MappyBase.js
import React, { useEffect, useMemo, useState } from 'react';
import {
    Divider,
    Collapse,
    Modal,
} from 'antd';
import * as XLSX from 'xlsx';
import { useTranslation } from 'react-i18next';

import useMappy from '../hooks/useMappy';
import FileUpload from './main/FileUpload';
import QrScannerButton from './main/QrScannerButton';
import ExcelPreview from './main/ExcelPreview';
import Settings from './main/Settings';
import AddressesTable from './main/AddressesTable';
import AddressButtons from './main/AddressButtons';
import Zones from './main/Zones';

const { Panel } = Collapse;

function MappyBase({ autoExtract }) {
    const { t } = useTranslation();

    // --------------------------------------------------------------------------------
    // HOOK: useMappy aggregator
    // --------------------------------------------------------------------------------
    const {
        data,
        addresses,
        headerRow,
        handleHeaderRowChange,
        headerRowError,
        startRow,
        handleStartRowChange,
        startRowError,
        columnsOptions,
        selectedColumns,
        handleSelectedColumnsChange,
        isSortVisible,
        orderBy,
        setOrderBy,
        sortOrder,
        setSortOrder,
        reorderedAddresses,
        setReorderedAddresses,
        addressesCollapsed,
        setAddressesCollapsed,
        addressValidation,
        zones,
        uploadedFileName,
        isLoading,
        isValidating,
        isSubmitting,
        handleReset,
        handleFileUpload,
        extractAndValidateAddresses, // For V1
        validateAddresses,           // For V2
        handleOnDragEnd,
        handleMoveUp,
        handleMoveDown,
        handleRemoveAddress,
        handleSubmit,
        handleAddressImport,
    } = useMappy({ autoExtract });

    // --------------------------------------------------------------------------------
    // V1 logic: addresses hidden until user clicks "Extract & Validate"
    // --------------------------------------------------------------------------------
    // We'll keep the "Extract & Validate" button always visible in V1.
    // Once the user clicks it, addresses become visible.
    const [addressesVisible, setAddressesVisible] = useState(autoExtract);

    // The function for V1 that sorts addresses if `orderBy` is set, then extracts & validates
    const onExtractAndValidateV1 = async () => {
        // Only for V1: do a one-time sort if user had set orderBy
        if (!autoExtract && orderBy) {
            const colIndex = XLSX.utils.decode_col(orderBy.trim().toUpperCase());
            const sorted = [...addresses].sort((a, b) => {
                const aValue = a.row[colIndex] || '';
                const bValue = b.row[colIndex] || '';
                return sortOrder === 'asc'
                    ? aValue.localeCompare(bValue)
                    : bValue.localeCompare(aValue);
            });
            setReorderedAddresses(sorted);
        } else {
            // If no orderBy => just set them unsorted
            setReorderedAddresses(addresses);
        }

        // Then call aggregator's "extractAndValidateAddresses"
        await extractAndValidateAddresses();

        // Show the addresses for V1
        setAddressesVisible(true);
        setAddressesCollapsed(false);
    };

    // --------------------------------------------------------------------------------
    // V2 logic: autoExtract => sort automatically if user sets orderBy
    // --------------------------------------------------------------------------------
    // We'll only do this effect if autoExtract == true
    // That ensures we do NOT re-sort for V1, which eliminates conflicts
    useEffect(() => {
        if (autoExtract) {
            if (addresses.length > 0 && orderBy) {
                const colIndex = XLSX.utils.decode_col(orderBy.trim().toUpperCase());
                const sorted = [...addresses].sort((a, b) => {
                    const aValue = a.row[colIndex] || '';
                    const bValue = b.row[colIndex] || '';
                    return sortOrder === 'asc'
                        ? aValue.localeCompare(bValue)
                        : bValue.localeCompare(aValue);
                });
                setReorderedAddresses(sorted);
            }
        }
    }, [
        autoExtract,
        addresses,
        orderBy,
        sortOrder,
        setReorderedAddresses,
    ]);

    // If zones are created, collapse addresses
    useEffect(() => {
        if (zones.length > 0) {
            setAddressesCollapsed(true);
        }
    }, [setAddressesCollapsed, zones]);

    // --------------------------------------------------------------------------------
    // Build a quick Excel preview (first 5 rows)
    // --------------------------------------------------------------------------------
    const excelPreview = useMemo(() => {
        if (data.length === 0) return [];
        const previewRows = 5;
        return data.slice(0, previewRows).map((row, idx) => {
            const rowNumber = idx + 1;
            const rowData = {};
            for (let i = 0; i < row.length; i++) {
                rowData[String.fromCharCode(65 + i)] = row[i];
            }
            return { key: rowNumber, rowNumber, ...rowData };
        });
    }, [data]);

    // Confirm before reset
    const confirmReset = () => {
        Modal.confirm({
            title: t('mappy.resetConfirmTitle'),
            content: t('mappy.resetConfirmContent'),
            okText: t('mappy.yes'),
            cancelText: t('mappy.no'),
            onOk: handleReset,
        });
    };

    // Filter out columns that user already selected
    const availableColumnsOptions = columnsOptions.filter(
        (option) => !selectedColumns.includes(option.value)
    );

    // --------------------------------------------------------------------------------
    // MAIN RENDER
    // --------------------------------------------------------------------------------
    return (
        <div>
            <h2>
                {t('mappy.title')} {autoExtract ? 'V2' : 'V1'}
            </h2>

            {!addressesCollapsed ? (
                <>
                    <FileUpload
                        handleFileUpload={handleFileUpload}
                        uploadedFileName={uploadedFileName}
                        isLoading={isLoading}
                    />

                    <QrScannerButton handleScanResult={handleAddressImport} />

                    <ExcelPreview
                        data={data}
                        excelPreview={excelPreview}
                        addressesVisible={addressesVisible}
                        reorderedAddresses={reorderedAddresses}
                    />

                    <Settings
                        data={data}
                        headerRow={headerRow}
                        handleHeaderRowChange={handleHeaderRowChange}
                        startRow={startRow}
                        handleStartRowChange={handleStartRowChange}
                        headerRowError={headerRowError}
                        startRowError={startRowError}
                        columnsOptions={columnsOptions}
                        availableColumnsOptions={availableColumnsOptions}
                        selectedColumns={selectedColumns}
                        handleSelectedColumnsChange={handleSelectedColumnsChange}
                        orderBy={orderBy}
                        setOrderBy={setOrderBy}
                        sortOrder={sortOrder}
                        setSortOrder={setSortOrder}
                        isSortVisible={isSortVisible}
                        autoExtract={autoExtract}
                        onExtractAndValidateV1={onExtractAndValidateV1}
                        isValidating={isValidating}
                    />

                {addressesVisible &&
                    reorderedAddresses.length > 0 &&
                    selectedColumns.length > 0 && (
                        <>
                            <Divider />

                            <h3>{t('mappy.extractedAddresses')}</h3>

                            <AddressesTable
                                reorderedAddresses={reorderedAddresses}
                                handleOnDragEnd={handleOnDragEnd}
                                handleMoveUp={handleMoveUp}
                                handleMoveDown={handleMoveDown}
                                handleRemoveAddress={handleRemoveAddress}
                                autoExtract={autoExtract}
                                addressValidation={addressValidation}
                            />

                            <AddressButtons
                                autoExtract={autoExtract}
                                addressesVisible={addressesVisible}
                                confirmReset={confirmReset}
                                reorderedAddresses={reorderedAddresses}
                                validateAddresses={validateAddresses}
                                isSubmitting={isSubmitting}
                                isValidating={isValidating}
                                handleSubmit={handleSubmit}
                            />
                        </>
                    )}
                </>
            ) : (
                <>
                    <Divider />

                    <Collapse
                        activeKey={addressesCollapsed ? [] : ["addresses"]}
                        onChange={(key) => {
                            if (key.length === 0) {
                                setAddressesCollapsed(true);
                            } else {
                                setAddressesCollapsed(false);
                            }
                        }}
                    >
                        <Panel header={t('mappy.extractedAddresses')} key="addresses" />
                    </Collapse>
                </>
            )}

            <Zones zones={zones} />
        </div>
    );
}

export default MappyBase;